import { Link } from 'react-router-dom';

import LetterLogo from '../../assets/color_letter_logo.png'
import DownloadNow from '../../assets/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg';
import AureusHandle from '../../assets/aureus_handle_2@3x.png';
import OxenFlowHandle from '../../assets/oxenflow_handle_1@3x.png'

const Footer = () => {

    return (
        <div className="p-10 bg-black">
            <div className="grid grid-cols-1 sm:grid-cols-2 bg-gray-500 h-auto p-10 sm:p-0 sm:h-60 rounded-xl">
                <div className='grid grid-cols-1 h-full gap-7 place-items-center'>
                    <div className='place-self-center relative group'>
                        <div className='w-56 z-0 absolute -inset-3 rounded-2xl bg-gradient-to-r from-white from-10% via-sky-500 via-30% to-emerald-500 to-90% ... blur opacity-25'/>
                        <img src={LetterLogo} alt='Oxen Flow Letter Logo' className='w-48 z-10 relative rounded-xl'/>
                    </div>
                    <a className='place-self-center' href="https://t.co/4P8ZGzDbKD">
                        <img src={DownloadNow} alt='Download now button' className='w-40'/>
                    </a>
                </div>
                <div className='grid grid-cols-1 sm:grid-cols-2 h-full gap-7 content-start p-7'>
                    <div className='grid grid-cols-1 justify-items-start'>
                        <h1 className='font-gotham text-white pb-1'>LEGAL</h1>
                        <a href="https://public.oxenflow.io/legal/terms_of_service.html" className="text-white h-7">
                            Terms Of Service
                        </a>
                        <a href="https://public.oxenflow.io/legal/privacy_policy.html" className="text-white h-7">
                            Privacy Policy
                        </a>
                    </div>

                    <div className='grid grid-cols-1 justify-items-start'>
                        <h1 className='font-gotham text-white pb-1'>SOCIAL</h1>
                        <a href="https://x.com/OxenFlow" className="text-white h-7">
                            <img src={OxenFlowHandle} alt='Oxen Flow X Handle' className='w-28'/>
                        </a>
                        <a href="https://x.com/AureusOx" className="text-white h-7">
                            <img src={AureusHandle} alt='Aureus Ox X Handle' className='w-28'/>
                        </a>
                    </div>

                    <div className='grid grid-cols-1 justify-items-start'>
                        <h1 className='font-gotham text-white pb-1'>SUPPORT</h1>
                        <Link to="/support" target="_top" className='text-white'>FAQs</Link>
                        <p className='text-white'>info@oxenflow.io</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;