import iconImage from '../../assets/0xenFlow_Color_0X_ONLY.jpg';
import downloadNow from '../../assets/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg';


const Header = () => {
    return (
        <header className="header">
            <div className='flex flex-wrap justify-center'>
                <div className='grid w-full p-4'>
                    <div className='place-self-center relative group'>
                        <div className='z-0 absolute -inset-3 rounded-2xl bg-gradient-to-r from-white from-10% via-sky-500 via-30% to-emerald-500 to-90% ... blur opacity-25'/>
                        <img src={iconImage} alt='Oxen Flow App Icon' className='z-10 relative h-40 rounded-3xl'/>
                    </div>
                </div>
                <div className='grid gap-0 w-full p-8'>
                    <h1 className="leading-none font-['Gotham-Black'] text-white">Your Crypto Wallet</h1>
                    <h1 className="leading-none font-['Gotham-Black'] text-white">on <span className='text-green-300'>EASY MODE</span></h1>
                </div>
                <div className='grid w-full p-8'>
                    <a className='place-self-center' href="https://t.co/4P8ZGzDbKD">
                        <img src={downloadNow} alt='Apple Download Now Button' className='w-48'/>
                    </a>
                </div>
            </div>
        </header>
    )
}

export default Header;